import { styled } from '@stitches/react';
import { FetchCartAndNotifyAll } from 'Commerce/Cart/Cart';
import {
  GetShippingDestination,
  SetShippingDestination,
} from 'Commerce/CountryPicker/ShippingDestination';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { useVariantData } from 'Shared/Providers/VariantProvider';

function SwitchShippingCountryCTA() {
  const {
    productLabels: { yourSelectedShippingCountryIs, switchShippingCountryTo },
  } = useTranslationData();
  const { state } = useVariantData();
  const selectedVariant = state.selectedVariant;
  const { languageRoute } = useAppSettingsData();
  const currentShippingDestination = GetShippingDestination();

  if (
    selectedVariant == null ||
    !selectedVariant.shippingNotAllowed ||
    selectedVariant.allowedShippingCountry == null
  ) {
    return <></>;
  }

  function handleClick(e: React.MouseEvent) {
    e.preventDefault();
    SetShippingDestination(selectedVariant?.allowedShippingCountry?.code);
    FetchCartAndNotifyAll(languageRoute);
  }

  return (
    <Wrapper>
      {yourSelectedShippingCountryIs} {currentShippingDestination.name}
      {'. '}
      <CTALink href="#" onClick={handleClick}>
        {switchShippingCountryTo} {selectedVariant.allowedShippingCountry.name}
      </CTALink>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  fontSize: '$fontSize75',
  textAlign: 'center',
  lineHeight: '$lh16',
  marginTop: '5px',
  fontWeight: '$fontWeightRegular',
});

const CTALink = styled('a', {
  color: '$onInteractiveEmphasized',
  fontWeight: '600',
});

export default SwitchShippingCountryCTA;
