import React from 'react';
import XHtmlArea from 'DesignComponents/Molecules/XHtmlArea/XHtmlArea';
import AccordionItem from 'DesignComponents/Molecules/Accordion/AccordionItem';
import AccordionItemModel from 'Models/Accordion/AccordionItemModel.interface';

type PropTypes = {
  items: AccordionItemModel[];
};

function ProductAccordion({ items }: PropTypes) {
  return (
    <>
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          header={item?.header}
          link={item?.link}
          opened={item?.opened}
        >
          <XHtmlArea
            content={item.text || ''}
            css={{
              px: 0,
              color: '$onSurface',
            }}
          />
        </AccordionItem>
      ))}
    </>
  );
}

export default ProductAccordion;
