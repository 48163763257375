import VariationModel from 'Models/KexVariation/VariationModel.interface';
import { Variant } from 'Enums/Variant.enum';

export type VariantStateType = {
  selectedVariant: VariationModel | null;
  hasColors: boolean;
  hasSizes: boolean;
  hasSelectedSize: boolean;
  errorSize: boolean;
};

type SET_INITIAL = {
  type: Variant.SET_INITIAL;
  selectedVariant: VariationModel;
  hasSizes: boolean;
};

type SET_VARIANT = {
  type: Variant.SET_VARIANT;
  selectedVariant: VariationModel;
  hasSelectedSize: boolean;
};

type SET_ADD_CART_ERROR = {
  type: Variant.SET_ADD_CART_ERROR;
  errorSize: boolean;
};

export type VariantActionType = SET_INITIAL | SET_VARIANT | SET_ADD_CART_ERROR;

/* eslint consistent-return: off */
const variantReducer = (
  state: VariantStateType,
  action: VariantActionType
): VariantStateType => {
  switch (action.type) {
    case Variant.SET_INITIAL: {
      return {
        ...state,
        selectedVariant: action.selectedVariant,
        hasSizes: action.hasSizes,
      };
    }
    case Variant.SET_VARIANT: {
      return {
        ...state,
        selectedVariant: action.selectedVariant,
        hasSelectedSize: action.hasSelectedSize,
        errorSize: false,
      };
    }
    case Variant.SET_ADD_CART_ERROR: {
      return {
        ...state,
        errorSize: action.errorSize,
      };
    }
  }
};

export default variantReducer;
