import { styled } from 'Theme/stitches.config';
import BulletPoint from 'DesignComponents/Atoms/BulletPoint/BulletPoint';
import React from 'react';

type ProductUSPType = {
  productUsp: string[];
  globalUsp: string[];
  label: string;
};

export function ProductUSP({
  productUsp = [''],
  globalUsp = [''],
  label,
}: ProductUSPType) {
  const usp = [...productUsp, ...globalUsp];

  const uspCleanup = usp.filter((item) => item);

  if (!uspCleanup || uspCleanup.length === 0) {
    return <></>;
  }

  return (
    <USPWrapper aria-label={label}>
      {uspCleanup.map((text, index) => {
        if (text !== '') {
          return (
            <React.Fragment key={text + index}>
              <USPItem>
                <BulletPoint text={text} size="m" />
              </USPItem>
            </React.Fragment>
          );
        }
        return <React.Fragment key={index} />;
      })}
    </USPWrapper>
  );
}

const USPWrapper = styled('ul', {
  border: '$borders$default solid $staticBorderDefault',
  py: 2,
  display: 'flex',
  flexDirection: 'column',
  color: '$onSurface',
  mb: 4,
});

const USPItem = styled('li', {
  px: 3,
  py: 2,
});
