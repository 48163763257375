import { styled } from 'Theme/stitches.config';
import { CSS } from '@stitches/react';
import { Check } from 'DesignSystem/Icons';

type PropType = {
  text: string;
  css?: CSS;
  size?: 's' | 'm' | 'l';
};

function BulletPoint({ text, css, size = 's' }: PropType) {
  return (
    <Wrapper css={css}>
      <Check size={size} color={'onInteractiveEmphasized'} />

      <span>{text}</span>
    </Wrapper>
  );
}

export default BulletPoint;

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '$onSurface',
  gap: '$s50',
  flexShrink: 0,
  fontSize: '$fontSize75',
  '@bpMin721': {
    fontSize: '$fontSize100',
  },
});
