import { StitchesVariantProps } from 'Shared/Types/types';
import { rotateAnimation } from 'DesignSystem/Animations/animation';
import { styled } from 'Theme/stitches.config';

type StitchesVariants = StitchesVariantProps<typeof Wrapper>;

interface Props {
  children: React.ReactElement;
  direction: StitchesVariants['direction'];
}

export default function AnimatedRotateIcon({ children, direction }: Props) {
  return <Wrapper direction={direction}>{children}</Wrapper>;
}

const Wrapper = styled('div', {
  ...rotateAnimation.transition,
  variants: {
    direction: {
      0: rotateAnimation[0],
      45: rotateAnimation[45],
      90: rotateAnimation[90],
      180: rotateAnimation[180],
      270: rotateAnimation[270],
    },
  },
});
