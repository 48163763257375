import { Info } from 'DesignSystem/Icons';
import { forwardRef } from 'react';
import { styled } from 'Theme/stitches.config';

type PropTypes = {
  errorLabel: string;
};

const Error = forwardRef<HTMLSpanElement, PropTypes>(({ errorLabel }, ref) => {
  return (
    <ErrorContainer ref={ref}>
      <Info size={'s'} color={'primary'} />
      {errorLabel}
    </ErrorContainer>
  );
});

const ErrorContainer = styled('span', {
  color: '$textSecondary',
  backgroundColor: '$secondary4',
  fs: 6,
  fontFamily: 'fontMedium',
  lineHeight: '$lh133',
  py: 2,
  px: 4,
  mb: 2,
  display: 'inline-flex',
  width: '100%',
});

export default Error;
