import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Info({ size, color }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle d="M12 16V12" stroke={color} strokeRound />
      <BaseIconPathStyle d="M12 8H12.01" stroke={color} strokeRound />
    </BaseSvgStyle>
  );
}

export default Info;
