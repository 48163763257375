import { styled } from 'Theme/stitches.config';
import { CSS } from '@stitches/react';
import PriceModel from 'Models/Price/PriceModel.interface';

type PropType = {
  nowPrice?: PriceModel;
  wasPrice?: PriceModel;
  discountText?: string;
  nowPriceSize?: CSS;
  wasPriceSize?: CSS;
  promotionDiscountPercentage?: number;
  css?: CSS;
};

function Price({
  nowPrice,
  wasPrice,
  nowPriceSize,
  wasPriceSize,
  css,
}: PropType) {
  let hasLoweredPrice = false;
  if (nowPrice && wasPrice) {
    hasLoweredPrice = nowPrice.price < wasPrice.price;
  }
  return (
    <PriceContainer css={css}>
      <NowPriceText css={{ ...nowPriceSize }}>
        {nowPrice?.priceAsString}{' '}
        {nowPrice?.currencySymbol && (
          <Currency>{nowPrice.currencySymbol}</Currency>
        )}
      </NowPriceText>
      {hasLoweredPrice && (
        <>
          <WasPriceText css={{ ...wasPriceSize }}>
            {wasPrice?.priceWithSymbol}
          </WasPriceText>
        </>
      )}
    </PriceContainer>
  );
}

const PriceContainer = styled('div', {
  display: 'flex',
  gap: '$s100',
  alignItems: 'baseline',
  w: '100%',
});
const NowPriceText = styled('span', {
  display: 'block',
  lineHeight: '$lh32',
  fontSize: '$fontSize500',
  fontFamily: 'fontBold',
  color: '$onSurface',
  '@bpMin721': {
    lineHeight: '$lh40',
    fontSize: '$fontSize700',
  },
});
const Currency = styled('span', {
  fontSize: '$fontSize100',
});
const WasPriceText = styled('s', {
  color: '$onWasPrice',
  fontSize: '$fontSize100',
});

export default Price;
