import { ReactElement, ReactNode } from 'react';
import Collapsible from 'react-collapsible';
import { Chevron } from 'DesignSystem/Icons';
import { styled } from 'Theme/stitches.config';
import AnimatedRotateIcon from 'DesignSystem/Icons/ExtenderIcons/AnimatedRotateIcon';
import { CSS } from '@stitches/react';
import ItemsNotifier from 'DesignComponents/Atoms/ItemsNotifier/ItemsNotifier';
import LinkModel from 'Models/Link/LinkModel.interface';

type Props = {
  header: string | ReactElement;
  opened?: boolean;
  link?: LinkModel;
  icon?: 'plus' | 'chevron';
  children?: ReactNode;
  isFooter?: boolean;
  css?: CSS;
  noOfItems?: number;
  hasCustomStyles?: boolean;
  onOpening?: () => void;
  onClosing?: () => void;
};

type CollapsibleWrapperProps = {
  opened?: boolean;
  children?: ReactNode;
  onOpening?: () => void;
  onClosing?: () => void;
};

function AccordionItem({
  header,
  opened,
  link,
  children,
  isFooter = false,
  css,
  noOfItems,
  hasCustomStyles = false,
  onOpening,
  onClosing,
}: Props) {
  const renderTrigger = (isOpen = false) => {
    return (
      <TitleWrapper>
        <HeaderButton>
          <TitleContent>{header}</TitleContent>
          <HeaderToolbar>
            <ItemsNotifier items={noOfItems || 0} position="relative" />
            <AnimatedRotateIcon direction={isOpen ? '180' : '0'}>
              <Chevron size="m" color={'primary'} />
            </AnimatedRotateIcon>
          </HeaderToolbar>
        </HeaderButton>
      </TitleWrapper>
    );
  };

  const CollapsibleWrapper = ({
    opened,
    onOpening,
    onClosing,
    children,
  }: CollapsibleWrapperProps) => {
    return (
      <Collapsible
        trigger={renderTrigger()}
        triggerWhenOpen={renderTrigger(true)}
        open={opened}
        transitionTime={300}
        overflowWhenOpen="visible"
        onOpening={onOpening}
        onClosing={onClosing}
      >
        {children}
      </Collapsible>
    );
  };

  return header ? (
    hasCustomStyles ? (
      <CollapsibleWrapper
        opened={opened}
        onOpening={onOpening}
        onClosing={onClosing}
      >
        {children}
      </CollapsibleWrapper>
    ) : (
      <ItemContainer isFooter={isFooter} css={css}>
        <CollapsibleWrapper
          opened={opened}
          onOpening={onOpening}
          onClosing={onClosing}
        >
          <Content>
            {children}
            {link?.href && <Link href={link.href}>{link?.text}</Link>}
          </Content>
        </CollapsibleWrapper>
      </ItemContainer>
    )
  ) : null;
}

const Link = styled('a', {
  color: '$slateGray',
  fontFamily: 'fontBold',
});

const HeaderButton = styled('button', {
  display: 'flex',
  justifyContent: 'space-between',
  w: '100%',
  my: '0',
  py: '17px',
  alignItems: 'center',
});

const HeaderToolbar = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

const ItemContainer = styled('div', {
  borderColor: '$interactiveBorderSeparator',
  borderTopWidth: 0,
  borderBottomWidth: '$borders$default',
  borderBottomStyle: 'solid',
  variants: {
    isFooter: {
      true: {
        borderColor: '$interactiveBorder',
      },
    },
  },
});

const TitleWrapper = styled('h3', {
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: 'fontSemiBold',
  borderColor: '$onSurface',
  color: '$onSurface',
  py: 'unset',
  pr: 2,
  my: 'unset',
  cursor: 'pointer',
});

const TitleContent = styled('div', {
  fontSize: '$fontSize200',
  ':last-of-type': {
    borderBottomWidth: '$borders$default',
  },
  textAlign: 'left',
});

const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  fs: 9,
  lineHeight: '$lh28',
  '> *': {
    mb: '$s150',
  },
  '& p': {
    mb: '$s150',
  },
});

export default AccordionItem;
