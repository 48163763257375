import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Chevron({ size, color, css }: ComponentProps) {
  return (
    <BaseSvgStyle
      css={css}
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle
        d="M18 9L12 15L6 9"
        stroke={color}
        strokeRound
        strokeWidth="1.5"
      />
    </BaseSvgStyle>
  );
}

export default Chevron;
