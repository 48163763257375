import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Check({ size, color, css }: ComponentProps) {
  return (
    <>
      <BaseSvgStyle
        css={css}
        size={size}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <BaseIconPathStyle
          d="M19.6326 6.75L8.81675 17.25L4.35986 12.9233"
          stroke={color}
          strokeRound
        />
      </BaseSvgStyle>
    </>
  );
}

export default Check;
